<template>
  <q-layout view="lHh Lpr lFf">
    <!-- Help button at conner -->
    <!--    <div
          v-show="!isWidgetOpen"
          @click="openWidget"
          style="
            width: 51px;
            height: 51px;
            border: 3px solid #333333;
            position: fixed;
            bottom: 11px;
            right: 18.5px;
            border-radius: 999px;
            z-index: 10000;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #e8aa10;
            cursor: pointer;
          ">
          <q-icon name="contact_support" size="30px" color="black"/>
        </div>-->
    <q-header elevated style="background-color: var(--base-white)">
      <q-toolbar
        class="u-max-w-[1149px] u-m-auto u-px-[29px] xl:u-px-0 u-h-[60px] sm:u-h-[80px] u-flex">
        <router-link :to="'/?lang=' + userStore.lang">
          <q-img
            alt="GoDuck"
            src="../assets/GoDuckVPNLogo.png"
            class="u-w-[120px] u-h-[36px] sm:u-w-[194px] sm:u-h-[56px]" />
        </router-link>
        <q-tabs
          :key="userStore.lang"
          class="u-hidden u-flex-1 lg:u-flex"
          inline-label
          indicator-color="white"
          content-class="u-text-[#666666]"
          active-class="u-text-[#FCB400]"
          outside-arrows
          mobile-arrows>
          <q-route-tab
            v-for="link in tabList"
            :key="link.title"
            class="q-route-tab"
            :to="link.link"
            :label="link.title" />
        </q-tabs>
        <hr class="u-flex-1 u-border-none lg:u-hidden" />
        <q-btn
          @mouseenter="onLangMenu({ isBtnIn: true })"
          @mouseleave="onLangMenu({ isBtnIn: false })"
          flat
          icon="img:icons/language.svg"
          class="u-hidden u-px-2 u-min-h-[30px] u-text-[#666666] u-text-[16px] u-leading-[22px] [&_.q-icon]:u-text-[125%] sm:u-h-9 lg:u-flex">
          <q-menu
            :model-value="langMenu.visible"
            @update:model-value="(visible) => onLangMenu({ visible })"
            @mouseenter="onLangMenu({ isMenuIn: true })"
            @mouseleave="onLangMenu({ isMenuIn: false })"
            fit
            auto-close>
            <q-list>
              <q-item clickable v-on:click="changeLang('zh-CN')">
                <q-item-section>简体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('zh-TW')">
                <q-item-section>繁体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('en-US')">
                <q-item-section>English</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <q-no-ssr>
          <q-btn
            @mouseenter="onMyMenu({ isBtnIn: true })"
            @mouseleave="onMyMenu({ isBtnIn: false })"
            :label="
              userStore.userInfo?.Account?.length > 10
                ? userStore.userInfo?.Account.slice(0, 10) + '...'
                : userStore.userInfo?.Account
            "
            flat
            class="u-hidden u-m-[0_15px_0_7px] u-px-[15px] u-min-h-[30px] u-text-[#666666] u-text-[16px] u-leading-[22px] u-normal-case sm:u-h-9"
            :class="{ 'lg:u-flex': !!userStore.token }">
            <q-menu
              v-model="myMenu.visible"
              @mouseenter="onMyMenu({ isMenuIn: true })"
              @mouseleave="onMyMenu({ isMenuIn: false })"
              fit
              auto-close>
              <q-list>
                <q-item
                  v-for="(item, i) in myList"
                  :key="i"
                  clickable
                  :to="item.link"
                  active-class="u-text-[#FCB400] u-font-bold">
                  <q-item-section>{{ item.title }}</q-item-section>
                </q-item>
                <q-item clickable @click="logoutProtection">
                  <q-item-section>{{ $t('header.logout') }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-btn
            :label="$t('header.login')"
            :to="'/login?lang=' + userStore.lang"
            flat
            class="u-hidden u-m-[0_15px_0_7px] u-px-[15px] u-min-h-[30px] u-text-[#666666] u-text-[16px] u-leading-[22px] sm:u-h-9"
            :class="{ 'lg:u-flex': !userStore.token }" />
        </q-no-ssr>
        <q-no-ssr>
          <q-btn
            :label="$t('global.freeTrial')"
            :to="
              userStore.token
                ? '/help/article/freevpn'
                : '/login?lang=' + userStore.lang
            "
            flat
            class="u-min-h-[30px] u-bg-[#FCB400] u-text-[#1C1C1C] u-text-sm sm:u-h-9 sm:u-text-[16px]" />
        </q-no-ssr>
        <q-btn
          flat
          dense
          round
          size="md"
          aria-label="Menu"
          @click="leftDrawerOpen = !leftDrawerOpen"
          class="u-ml-2 u-text-[#1C1C1C] lg:u-hidden">
          <q-icon name="menu" color="black_own" />
        </q-btn>
      </q-toolbar>
    </q-header>
    <q-drawer
      side="right"
      v-model="leftDrawerOpen"
      class="u-flex u-flex-col lg:u-hidden"
      bordered>
      <q-icon
        name="las la-times"
        size="24px"
        class="u-absolute u-top-5 u-left-5 u-z-10"
        @click="leftDrawerOpen = !leftDrawerOpen" />
      <q-no-ssr>
        <q-list class="u-pt-11">
          <q-item
            v-if="userStore.token"
            clickable
            class="u-pl-[90px] u-min-h-11 u-flex-col u-text-[#1C1C1C] u-no-underline u-font-bold">
            <div>{{ userStore.userInfo?.Account }}</div>
            <div>
              <span
                class="u-px-2 u-py-0.5 u-rounded-[3px] u-text-white"
                :class="
                  userStore.userInfo?.VipTimeLeft
                    ? 'u-bg-[#e8aa10]'
                    : 'u-bg-[#ADADAD]'
                ">
                {{ $t('header.vip') }}
              </span>
              <span class="u-ml-2 u-text-[#666666]">
                {{ $t('header.vipDaysLeft', { value: vipDaysLeft }) }}
              </span>
            </div>
          </q-item>
          <template v-for="item in asideList" :key="item.title">
            <q-item
              clickable
              tag="a"
              target="_self"
              :href="item.link"
              class="u-pl-[90px] u-min-h-11 u-items-center u-text-[#666666] u-no-underline">
              {{ item.title }}
            </q-item>
          </template>
          <q-item
            v-if="userStore.token"
            clickable
            class="u-pl-[90px] u-min-h-11 u-items-center u-text-[#666666] u-no-underline"
            @click="logoutProtection">
            {{ $t('header.logout') }}
          </q-item>
        </q-list>
      </q-no-ssr>
      <div class="u-flex-1"></div>
      <q-item
        class="u-pb-[84px] u-pl-[74px] u-min-h-11 u-items-center u-text-[#666666] u-no-underline">
        <q-btn
          :label="$t('header.language')"
          flat
          icon="img:icons/language.svg"
          icon-right="arrow_drop_down"
          class="u-h-11 u-font-normal u-text-sm [&_.q-icon]:u-text-[125%] [&_.q-icon.on-left]:u-mr-2">
          <q-menu fit auto-close>
            <q-list>
              <q-item clickable v-on:click="changeLang('zh-CN')">
                <q-item-section>简体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('zh-TW')">
                <q-item-section>繁体中文</q-item-section>
              </q-item>
              <q-item clickable v-on:click="changeLang('en-US')">
                <q-item-section>English</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-item>
    </q-drawer>
    <q-page-container style="padding-bottom: 0px !important">
      <router-view :key="$route.fullPath" />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { debounce, useQuasar } from 'quasar'
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import moment from 'moment'
import { useUserStore } from 'src/stores/user'
import { getUserInfoRep, getUserInfoReq } from 'src/api/login'
import {
  base64Decode,
  findUsefulMsg,
  getDeviceName,
  getUA,
  url
} from 'src/utils/util'

const $q = useQuasar()
const leftDrawerOpen = ref(false)
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()

const logout = () => {
  if (userStore.token !== null) {
    $q.notify({
      type: 'positive',
      message: t('alert.logout'),
      position: 'top'
    })
    userStore.logout()
    router.push({
      path: '/',
      query: { lang: userStore.lang }
    })
  }
}

function logoutProtection() {
  if (userStore.token) {
    $q.dialog({
      title: t('logoutDialog.title'),
      message: t('logoutDialog.content'),
      persistent: true,
      ok: t('logoutDialog.ok'),
      cancel: t('global.cancel')
    }).onOk(() => {
      logout()
    })
  } else {
    router.push({
      path: '/login',
      query: { lang: userStore.lang }
    })
  }
}

const getUserInfo = async (param) => {
  if (userStore.token) {
    await getUserInfoReq(param).then((response) => {
      const rep = getUserInfoRep(response)
      userStore.updateUserInfo(rep.data)
    })
  }
}

const vipDaysLeft = computed(() => {
  const endTime = moment(userStore.userInfo?.VipTimeLeft * 1000)
  const now = moment()
  return moment().isSameOrBefore(endTime, 'day') ? endTime.diff(now, 'days') : 0
})

watch(
  () => userStore.userLoginInfo?.deviceId,
  async () => {
    if (userStore.userLoginInfo?.deviceId) {
      await getUserInfo(userStore.userLoginInfo.deviceId)
    }
  }
)

/////////////// renew after payment ///////////////
watch(
  () => route.query.renew,
  async () => {
    if (route.query.renew && userStore.userLoginInfo?.deviceId) {
      await getUserInfo(userStore.userLoginInfo.deviceId)

      // eslint-disable-next-line no-unused-vars
      const { renew, ...restQuery } = route.query
      router.replace({ query: restQuery })
    }
  }
)
/////////////// end ///////////////

const linksList = computed(() => [
  {
    title: t('header.login'),
    link: '/login?lang=' + userStore.lang,
    hidden: !!userStore.token,
    position: 'aside'
  },
  {
    title: t('header.home'),
    link: '/?lang=' + userStore.lang
  },
  {
    title: t('header.pricing'),
    link: '/pricing?lang=' + userStore.lang
  },
  {
    title: t('header.download'),
    link: '/download?lang=' + userStore.lang
  },
  {
    title: t('header.promote'),
    link: '/promote?lang=' + userStore.lang
  },
  {
    title: t('header.blog'),
    link: '/blog?lang=' + userStore.lang
  },
  {
    title: t('header.help'),
    link: '/help?lang=' + userStore.lang
  },
  {
    title: t('header.myAccount'),
    link: '/my-account?lang=' + userStore.lang,
    hidden: !userStore.token,
    position: 'aside'
  },
  {
    title: t('header.myAffiliateProgram'),
    link: '/my-affiliate-program?lang=' + userStore.lang,
    hidden: !userStore.token,
    position: 'aside'
  },
  {
    title: t('header.changePassword'),
    link: '/change-password?lang=' + userStore.lang,
    hidden: !userStore.token,
    position: 'aside'
  }
])
const myList = computed(() =>
  linksList.value.filter((item) => !item.hidden && item.position === 'aside')
)
const tabList = computed(() =>
  linksList.value.filter(
    (item) => !item.hidden && (!item.position || item.position === 'tab')
  )
)
const asideList = computed(() =>
  linksList.value.filter(
    (item) => !item.hidden && (!item.position || item.position === 'aside')
  )
)

const changeLang = (lang) => {
  userStore.setLang(lang)
  locale.value = lang
  router.push({
    query: { ...route.query, lang }
  })
}

// make Menu clickable and hoverable
const langMenu = reactive({
  isBtnIn: false,
  isMenuIn: false,
  visible: false
})
const onLangMenu = debounce(
  (
    value = {
      visible: undefined,
      isBtnIn: langMenu.isBtnIn,
      isMenuIn: langMenu.isMenuIn
    }
  ) => {
    if (typeof value.visible === 'boolean') {
      langMenu.isBtnIn = false
      langMenu.isMenuIn = false
      langMenu.visible = value.visible
    } else {
      langMenu.isBtnIn = value.isBtnIn
      langMenu.isMenuIn = value.isMenuIn
      langMenu.visible = value.isBtnIn || value.isMenuIn
    }
  },
  100
)

// make Menu clickable and hoverable
const myMenu = reactive({
  visible: false,
  isBtnIn: false,
  isMenuIn: false
})
const onMyMenu = debounce(
  (
    value = {
      visible: undefined,
      isBtnIn: myMenu.isBtnIn,
      isMenuIn: myMenu.isMenuIn
    }
  ) => {
    if (typeof value.visible === 'boolean') {
      myMenu.isBtnIn = false
      myMenu.isMenuIn = false
      myMenu.visible = value.visible
    } else {
      myMenu.isBtnIn = value.isBtnIn
      myMenu.isMenuIn = value.isMenuIn
      myMenu.visible = value.isBtnIn || value.isMenuIn
    }
  },
  100
)

// resume token in CSR
onBeforeMount(() => {
  userStore.resume()
})

// Android 支付时，会跳到网页上支付。使用到網址 query 的 redirect=u 和 login 參數
onMounted(() => {
  if (route.query.redirect && route.query.redirect === 'u') {
    if (route.query.login) {
      $q.notify({
        type: 'positive',
        message: t('alert.login'),
        position: 'top'
      })
    } else {
      $q.notify({
        type: 'positive',
        message: t('alert.logout'),
        position: 'top'
      })
    }
  }
})

/////////////// auto login ///////////////

const autoLoginPricing = async (usrname, pwd) => {
  const data = {
    username: atob(usrname),
    password: atob(pwd),
    deviceId: 'web',
    platform: 'Web:' + getUA(),
    deviceName: getDeviceName()
  }

  $q.loading.show()

  await userStore
    .login(data)
    .then(async () => {
      router.push({
        path: '/pricing',
        query: {
          lang: route.query.lang,
          pid: route.query.pid,
          group: route.query.group
        }
      })
    })
    .catch((err) => {
      $q.notify({
        type: 'negative',
        message: findUsefulMsg(
          t('errorCode.' + err.code),
          t('httpCode.' + err.data.status)
        ),
        position: 'top'
      })
    })
    .finally(() => {
      $q.loading.hide()
    })
}

const redirectByQuery = () => {
  const redirect = base64Decode([].concat(route.query.redirect)[0])
  if (redirect && redirect.startsWith('/')) {
    router.replace(redirect)
    return
  } else {
    router.replace({
      path: '/',
      query: {
        lang: userStore.lang
      }
    })
  }
}

const autoLoginFromApp = async (token, deviceId) => {
  $q.loading.show()

  await userStore
    .setRoles({
      token,
      deviceId
    })
    .then(async () => {
      if (['/login', '/signup'].some((path) => path === route.path)) {
        redirectByQuery()
      } else {
        const next = url(route.fullPath, {
          base: '/',
          query: { d: null, t: null }
        })
        router.replace(next)
      }
    })
    .catch((err) => {
      $q.notify({
        type: 'negative',
        message: findUsefulMsg(
          t('errorCode.' + err.code),
          t('httpCode.' + err.data.status)
        ),
        position: 'top'
      })
    })
    .finally(() => {
      $q.loading.hide()
    })
}

onMounted(() => {
  if (route.query.usr && route.query.goduck) {
    autoLoginPricing(route.query.usr, route.query.goduck)
    return
  }

  // 來自 app 自動登入並轉跳頁面
  if (route.query.t && route.query.d) {
    autoLoginFromApp(route.query.t, route.query.d)
    return
  }

  // 已登入者直接轉跳頁面
  // 因需要 token 的頁面無需 SSR，且 token 存於瀏覽器，故交由 CSR 轉跳頁面
  if (
    userStore.token &&
    ['/login', '/signup'].some((path) => path === route.path)
  ) {
    redirectByQuery()
    return
  }
})
</script>

<style lang="scss" scoped>
:deep() {
  .q-tabs {
    margin-left: 35px;
    min-width: 0;
  }
  .q-tabs.q-tabs--scrollable.q-tabs__arrows--outside.q-tabs--horizontal {
    padding: 0 35px !important;
    margin-left: 0;
  }
  .q-tabs__content {
    justify-content: flex-start !important;
  }
  .q-tab {
    flex: none !important;
    padding: 0 15px;
  }
  .q-tab__label {
    font-size: 16px !important;
    font-weight: normal;
  }
  .q-tab--active .q-tab__label {
    font-weight: bold;
  }
  .q-tabs__arrow {
    min-width: 35px;
    width: 35px !important;
  }

  .q-icon.on-right {
    margin-left: 0;
  }
}
</style>
